<template>
  <div>
    <img v-if="getLogo(partnerInfo)" :src="getLogo(partnerInfo)" :alt="`Coopérative ${getName(partnerInfo)}`" />
    <div v-else data-test="partner-no-logo">{{ getName(partnerInfo) }}</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import logoActeo from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_acteo.svg'
import logoAgora from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_agora.png'
import logoAin from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_ain.png'
import logoBonneval from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_bonneval.png'
import logoCavac from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_cavac.png'
import logoCavale from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_cavale.png'
import logoCoopazur from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_azurcomptoir.png'
import logoEmc2 from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_emc2.png'
import logoHoupiez from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_houpiez.png'
import logoInovitis from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_inovitis.png'
import logoLaFlandreBleu from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_la_flandre_bleu.png'
import logoLorca from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_lorca.jpg'
import logoNatup from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_natup.png'
import logoNoriap from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_noriap.png'
import logoOcealia from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_ocealia.png'
import logoOxyane from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_oxyane.png'
import logoSevreBelle from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_sevre_et_belle.png'
import logoTerraDemo from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_terra_demonstration.png'
import logoUneal from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_uneal.png'
import logoValDeGascogne from '@b2ag/design-system/dist/themes/aladin/assets/logos/cooperatives/cooperative_val_de_gascogne.png'
import logoAgralia from '@/assets/coop_agralia.png'
import logoMosaic from '@/assets/coop_mosaic.png'
import logoMaisadour from '@/assets/coop_vega_maisadour.png'
import logoArterris from '@/assets/cooperative_29.png'
import logoNovasol from '@/assets/cooperative_30.png'

export default defineComponent({
  name: 'PPartnerLogo',
  props: {
    partnerInfo: [String, Number],
  },
  setup() {
    const partners = [
      { id: 1, name: 'Agora', logo: logoAgora },
      { id: 2, name: 'Agralia', logo: logoAgralia },
      { id: 3, name: 'NatUp', logo: logoNatup },
      { id: 4, name: 'Inovitis', logo: logoInovitis },
      { id: 5, name: 'EMC2', logo: logoEmc2 },
      { id: 6, name: 'VEGA* Maïsadour', logo: logoMaisadour },
      { id: 7, name: 'Noriap', logo: logoNoriap },
      { id: 8, name: 'Océalia', logo: logoOcealia },
      { id: 9, name: 'Unéal', logo: logoUneal },
      { id: 10, name: 'Val de Gascogne', logo: logoValDeGascogne },
      { id: 11, name: 'Oxyane', logo: logoOxyane },
      { id: 12, name: 'Terra Démonstration', logo: logoTerraDemo },
      { id: 13, name: 'Ain', logo: logoAin },
      { id: 14, name: 'La Flandre', logo: logoLaFlandreBleu },
      { id: 15, name: 'Cavac', logo: logoCavac },
      { id: 16, name: 'MOSAIC', logo: logoMosaic },
      { id: 17, name: 'Houpiez', logo: logoHoupiez },
      { id: 18, name: 'Coopazur', logo: logoCoopazur },
      { id: 19, name: 'Sèvre et Belle', logo: logoSevreBelle },
      { id: 20, name: 'Cavale', logo: logoCavale },
      { id: 21, name: 'Bonneval Beauce et Perche', logo: logoBonneval },
      { id: 22, name: 'Actéo', logo: logoActeo },
      { id: 23, name: 'Duransia', logo: null },
      { id: 24, name: 'Lorca', logo: logoLorca },
      { id: 27, name: 'Ecovigne', logo: null },
      { id: 29, name: 'Arterris', logo: logoArterris },
      { id: 30, name: 'Novasol', logo: logoNovasol },
    ]

    function getPartnerFromInfo(info) {
      let partner = {}

      if (typeof info === 'number') {
        partner = partners.find((p) => p.id === info)
      } else if (typeof info === 'string') {
        partner = partners.find((p) => p.name.toUpperCase() === info.toUpperCase())
      }

      return partner
    }

    function getLogo(info) {
      return getPartnerFromInfo(info).logo
    }

    function getName(info) {
      return getPartnerFromInfo(info).name
    }

    return {
      getLogo,
      getName,
    }
  },
})
</script>
